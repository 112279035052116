import React from 'react';


const Footer = () => {

  return (
    <footer>
      <div>SUPPORT THIS PROJECT</div>
      <ul>
        <li>
          <span className="text-grey">BTC [</span>3EVe1TMdqTouFwV9dYk99vDYtsTCTy4iL6<span className="text-grey">]</span>
        </li>
        <li>
          <span className="text-grey">ETH [</span>0x33956c8d66fd4991FBB9F71dd0D517c8544A0C3d<span className="text-grey">]</span>
        </li>
        <li>
          <span className="text-grey">XMR [</span>48JMfXuyjPHYptydtRGMLKAAvKzDyEkaWf9zygejDqzeT83P2pToWYVUBw2rhWE7eX5EPsrgdb9AU7VFGqNfyXrs8mDWZEC<span className="text-grey">]</span>
        </li>
      </ul>
    </footer>
  )
};

export default Footer;
