import React from 'react';
import { withRouter } from 'react-router';
import Tone from 'tone';
import StartAudioContext from 'startaudiocontext';


export const AppContext = React.createContext();

class AppContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.getCoins = () => {
      const { settings } = this.state;
      return fetch(`${settings.dataURL}/coins`)
        .then(res => res.json())
        .then(coins => this.setState({ coins }))
        .catch(err => console.error(err));
    };

    this.getCoinData = coinName => {
      const { coins, settings } = this.state;
      let currentCoin = coins.find(coin => coin.name === coinName);
      let currentCoinIndex = coins.findIndex(coin => coin.name === coinName);
      return fetch(`${settings.dataURL}/coin/${currentCoin.name}`)
        .then(res => res.json())
        .then(res => {
          coins.map(coin => coin.selected = false);
          coins[currentCoinIndex] = { ...currentCoin, ...res, selected: true };
          return coins[currentCoinIndex];
        })
        .catch(err => console.error(err))
        .finally(() => this.setState({ coins }));
    };

    let bgSynth;
    let pluckSynth;
    let kickSynth;
    let pwm;
    let pulse;

    const triggerPluckSynth = (note, time) => pluckSynth.triggerAttackRelease('C2', '8n', time);
    const kickLoop = new Tone.Loop(time => {
      kickSynth.triggerAttackRelease('C0', '8n', time)
    }, '4n');

    this.play = currentCoin => {
      if (currentCoin && currentCoin.networkData) {
        StartAudioContext(Tone.context, '.menu-item');
        if (bgSynth) bgSynth.disconnect();
        if (pluckSynth) pluckSynth.disconnect();
        if (kickSynth) kickSynth.disconnect();
        if (pwm) pwm.stop();
        if (pulse) pulse.stop();
        Tone.Transport.stop();

        const bgSynthOptions = {
          oscillator: {
            type: 'fmsquare',
            modulationType: 'sawtooth',
            modulationIndex: 3,
            harmonicity: 3.4,
          },
          envelope: {
            attack: 0.0001,
            decay: 0.01,
            sustain: 0.1,
            release: 0.1,
          },
        };

        const pluckSynthOptions = {
          attackNoise: 2,
          dampening: 1000,
          resonance: 0.4,
          volume: 10,
        };

        const kickSynthOptions = {
          pitchDecay: 0.01,
          octaves: 20,
          oscillator: {
            type: 'sine',
          },
          envelope: {
            attack: 0.001,
            decay: 0.4,
            sustain: 0.01,
            release: 1.4,
            attackCurve: 'exponential',
          }
        };

        const frequency = {
          frequency: currentCoin.networkData.target_block_time / 2,
          detune: 0,
          phase: 0,
          modulationFrequency: 0.4,
        };

        const pulseOptions = {
          frequency: currentCoin.networkData.target_block_time / 2,
          detune: 0,
          phase: 0,
          width: 1,
        };

        bgSynth = new Tone.Synth(bgSynthOptions).toMaster();
        bgSynth.triggerAttack(currentCoin.networkData.hashrate * 0.00005);

        kickSynth = new Tone.MembraneSynth(kickSynthOptions).toMaster();
        kickLoop.start(0);

        pluckSynth = new Tone.PluckSynth(pluckSynthOptions).toMaster();
        Tone.Transport.schedule(triggerPluckSynth, 0);
        Tone.Transport.schedule(triggerPluckSynth, currentCoin.networkData.poolsminers / 10);
        Tone.Transport.schedule(triggerPluckSynth, currentCoin.networkData.poolsminers / 5);
        Tone.Transport.schedule(triggerPluckSynth, currentCoin.networkData.poolsminers / 2.5);
        // Tone.Transport.schedule(triggerPluckSynth, '0:2');
        // Tone.Transport.schedule(triggerPluckSynth, '0:2:2.5');
        // Tone.Transport.loop = true;

        Tone.Transport.bpm.value = currentCoin.networkData.target_block_time;
        Tone.Transport.start();


        pwm = new Tone.PWMOscillator(frequency).toMaster();
        pwm.volume.value = -20;
        pwm.start();

        pulse = new Tone.FatOscillator(pulseOptions).toMaster();
        pulse.volume.value = -10;
        pulse.start();
      }
    };

    this.state = {
      actions: {
        getCoins: this.getCoins,
        getCoinData: this.getCoinData,
        play: this.play,
      },
      coins: [],
      settings: {
        getCoinDataInterval: 1,  // in minutes
        dataURL: 'https://api.cryptotone.io',
      }
    };
  }

  componentDidMount() {
    this.initApp();
  }

  componentWillUnmount() {
    this.clearApp();
  }

  initApp = () => {
    const { actions } = this.state;
    actions.getCoins();
    // this.getCoinDataInterval = setInterval(actions.getCoinData, settings.getCoinDataInterval * 60 * 1000);
  };

  clearApp = () => {
    if (this.getCoinDataInterval) clearInterval(this.getCoinDataInterval);
  };

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

export default withRouter(AppContextProvider);
