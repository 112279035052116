import React, { useContext } from 'react';

import { AppContext } from '../ContextProvider';


const Menu = () => {
  const { actions, coins } = useContext(AppContext);

  return (
    <menu>
      {coins.sort((a, b) => a.name.localeCompare(b.name)).map(coin =>
        <div className="menu-item" key={coin.symbol}>
          <input
            type="radio"
            id={coin.symbol}
            name="coin"
            value={coin.name}
            onClick={e => actions.getCoinData(e.target.value).then(coin => actions.play(coin))}
          />
          <label htmlFor={coin.symbol}>
            <img src={require(`../static/img/${coin.symbol}.png`)} alt={coin.name} />
            {coin.name}
          </label>
        </div>
      )}
    </menu>
  )
};

export default Menu;
