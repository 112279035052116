import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AppContextProvider from '../ContextProvider';
import Layout from './Layout';
import Main from './Main';
import '../static/css/layout.css';
import '../static/css/style.css';


const App = () => (
  <Router>
    <AppContextProvider>

      <Layout exact path="/" component={Main} />

    </AppContextProvider>
  </Router>
);

export default App;
