import React from 'react';
import { Route } from 'react-router-dom';

import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';


const Layout = (props) => {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props =>
        <>
          <Header/>
          <Menu />
          <Component {...props} />
          <Footer />
        </>
      }
    />
  );
};

export default Layout;
