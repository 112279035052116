import React, { useContext } from 'react';

import { AppContext } from '../ContextProvider';


const Main = () => {
  const { coins } = useContext(AppContext);
  const currentCoin = coins.find(c => c.selected);
  const { coinConfig, networkData } = currentCoin || {};

  return (
    <main>
      {!currentCoin &&
        <div>
          Choose one of the coins from the menu.
        </div>
      }
      {networkData &&
        <>
          <div>Difficulty: {currentCoin.networkData.difficulty}</div>
          <div>Hash Rate: {currentCoin.networkData.hashrate}</div>
          <div>Height: {currentCoin.networkData.height}</div>
          <div>Pool Hash Rate: {currentCoin.networkData.poolshash}</div>
          <div>Miners: {currentCoin.networkData.poolsminers}</div>
          <div>Block Time: {currentCoin.networkData.target_block_time}</div>
        </>
      }
      {coinConfig &&
        <>
          <div>Max. Block Number: {currentCoin.coinConfig.maxBlockNumber}</div>
          <div>Unlock Window: {currentCoin.coinConfig.unlockWindow}</div>
          <div>Coin Decimals: {currentCoin.coinConfig.coinDecimals}</div>
        </>
      }
    </main>
  )
};

export default Main;
