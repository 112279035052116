import React from 'react';


const Header = () => {

  return (
    <header>
      <div>cryptotone.io</div>
      <small>industrial blockchain experiment by <strong>ákkúrats!</strong></small>
    </header>
  )
};

export default Header;
